<template>
  <div class="monitor-on-call">
    <span :class="'font-bold ' + statusColor">
      <b>
        {{
          $t(`extensions-monitor.table.onCall.${rowData.on_call || 'false'}`)
        }}
      </b>
    </span>

    <button
      v-if="
        rowData.on_call &&
          extensionStatus === 'connected' &&
          rowData.ext !== currentExtension
      "
      class="px-2 py-2 btn btn-primary btn-sm"
      @click="spy(rowData)"
    >
      {{ $t('extensions-monitor.table.onCall.spy') }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'extensionsStatus',
  props: {
    rowData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      phone: null
    }
  },
  computed: {
    ...mapGetters(['extensionStatus']),
    statusColor() {
      switch (this.rowData.on_call) {
        case true:
          return 'text-success'
        default:
          return 'text-primary'
      }
    },
    currentExtension() {
      return this.$root.$refs.phone
        ? this.$root.$refs.phone.extension.ext
        : null
    }
  },
  methods: {
    spy(rowData) {
      const phone = this.$root.$refs.phone
      if (!phone) return

      phone.spy(rowData.ext)
    }
  }
}
</script>
<style lang="scss" scoped></style>
